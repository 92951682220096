body {
  font-family: $fnt-main;
  color: $text-color;
  background-color: #f5f5f5;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  @include media-up(sm) {
    width: $container-sm;
  }

  @include media-up(md) {
    width: $container-md;
  }

  @include media-up(lg) {
    width: $container-lg;
  }
}

.main {
  min-height: 100vh;
  width: 100%;
  @include flex($j-center, $a-center);
}

.box {
  padding: 25px 35px;
  background-color: $clr-white;
  text-align: center;
  box-shadow: 0 1px 3px rgba($clr-black, .1);

  &__title {
    font-weight: 300;
    font-size: 50px;
  }

  &__contacts {
    margin-top: 1em;
  }
}