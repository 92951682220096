@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,700&subset=cyrillic');

//MY VARS
$fnt-main: 'Comfortaa', sans-serif;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$container-sm: 750px;
$container-md: 970px;
$container-lg: 1170px;
//--MY VARS

//MY COLOR
$clr-white: #fff;
$clr-black: #000;

$text-color: #222222;

//--MY COLOR
