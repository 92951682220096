.c-link {
  text-decoration: none;
  color: $text-color;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 2px;
  transition: .4s;

  &:hover {
    color: #12A5F4;
  }
}