@mixin transition {
  transition: all ease .4s;
}

@mixin img-center {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin placeholder($clr) {
  &::-webkit-input-placeholder {
    color: $clr;
  }
  &::-moz-placeholder {
    color: $clr;
  }
  &::-ms-input-placeholder {
    color: $clr;
  }
}

//FLEXBOX
$j-fs: flex-start;
$j-end: flex-end;
$j-center: center;
$j-sb: space-between;
$j-sa: space-around;

$a-fs: flex-start;
$a-end: flex-end;
$a-center: center;
$a-bl: baselie;
$a-st: stretch;

@mixin flex($flex-j: $j-fs, $flex-a: $a-st) {
  display: flex;
  justify-content: $flex-j;
  align-items: $flex-a;
}

//MEDIA
@mixin media-up($media) {
  @if $media == xs {
    @media (min-width: $screen-xs) { @content; }
  }
  @else if $media == sm {
    @media (min-width: $screen-sm) { @content; }
  }
  @else if $media == md {
    @media (min-width: $screen-md) { @content; }
  }
  @else if $media == lg {
    @media (min-width: $screen-lg) { @content; }
  }
}

@mixin media-down($media) {
  @if $media == xs {
    @media (max-width: $screen-xs - 1) { @content; }
  }
  @else if $media == sm {
    @media (max-width: $screen-sm - 1) { @content; }
  }
  @else if $media == md {
    @media (max-width: $screen-md - 1) { @content; }
  }
  @else if $media == lg {
    @media (max-width: $screen-lg - 1) { @content; }
  }
}

//OPACITY

@mixin opacity($op-start: .5, $op-end: 1) {
  opacity: $op-start;
  transition: opacity ease .4s;

  &:hover {
    opacity: $op-end;
  }
}